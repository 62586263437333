import React from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";

function Gallery() {


    return <Flex
        width="100%"
        height={`${window.innerHeight}px`}
        overflow='hidden'
        backgroundColor='#0C0C0C'
        justifyContent='center'
        alignItems='center'
    >
        <Flex position='absolute' color='grey' left='20px' top='20px' cursor='pointer'>{'< Home'}</Flex>
        <Flex textAlign='center' alignItems='center'>
            <Box backgroundColor='blue' borderRadius='20px' width='500px' height='300px'></Box>
            <Box width='500px' margin='0 20px'>
                <Text color='white'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus diam ligula, bibendum nec dignissim et, tempus at nunc. Integer diam enim, placerat id commodo sit amet, sollicitudin in metus. Curabitur mi nulla, lacinia nec tellus ac, porttitor laoreet elit. Vestibulum efficitur lorem non magna pretium, luctus aliquam lorem blandit. Donec laoreet tincidunt scelerisque. Cras vitae nisi egestas, rutrum lectus et, interdum dolor. Pellentesque at risus erat. Vestibulum laoreet, dolor ut vulputate elementum, risus dolor eleifend lacus, ac suscipit ipsum ante in neque. Sed placerat odio ex, nec suscipit dolor malesuada vel. Fusce non ligula ac lorem tristique bibendum interdum ac orci.
                </Text>
            </Box>
        </Flex>
    </Flex>
}

export default Gallery;
