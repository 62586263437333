import React from 'react';
import emailjs from '@emailjs/browser'
import { Box, Link, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom'
import StarField from './StarField';
import SlideIn from './CardPages/SlideIn';
import HomePage from './CardPages/HomePage';
import ProfessionalExperience from './CardPages/ProfessionalExperience';
import Education from './CardPages/Education';
import Portfolio from './CardPages/Portfolio';
import AnaglyphText from './AnaglyphText';
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import SpotifyIcon from '@mui/icons-material/LibraryMusic';

const LinkedInLink = 'https://www.linkedin.com/in/jkimminau/'
const githubLink = 'https://github.com/jkimminau/'
// Make instagram public
const instagramLink = 'https://www.instagram.com/jamisonkimminau/'
const spotifyLink = 'https://open.spotify.com/user/1225066737'

function gradient(startColor, endColor, steps) {
    var start = {
            'Hex'   : startColor,
            'R'     : parseInt(startColor.slice(1,3), 16),
            'G'     : parseInt(startColor.slice(3,5), 16),
            'B'     : parseInt(startColor.slice(5,7), 16)
    }
    var end = {
            'Hex'   : endColor,
            'R'     : parseInt(endColor.slice(1,3), 16),
            'G'     : parseInt(endColor.slice(3,5), 16),
            'B'     : parseInt(endColor.slice(5,7), 16)
    }
    let diffR = end['R'] - start['R'];
    let diffG = end['G'] - start['G'];
    let diffB = end['B'] - start['B'];

    let stepsHex  = new Array();
    let stepsR    = new Array();
    let stepsG    = new Array();
    let stepsB    = new Array();

    for(var i = 0; i <= steps; i++) {
            stepsR[i] = start['R'] + ((diffR / steps) * i);
            stepsG[i] = start['G'] + ((diffG / steps) * i);
            stepsB[i] = start['B'] + ((diffB / steps) * i);
            stepsHex[i] = '#' + Math.round(stepsR[i]).toString(16) + '' + Math.round(stepsG[i]).toString(16) + '' + Math.round(stepsB[i]).toString(16);
    }
    return stepsHex;

}

class Home extends React.Component {
    state = {
        page: 0,
        showMenu: false,
        anchorEl: null,
        theposition: 0,
    }

    openMenu = () => {
        this.setState({ showMenu: true })
    }

    setAnchorEl = (anchorEl) => {
        this.setState({ anchorEl: () => {} })
    }

    setAnchor = (e) => {
        setAnchorEl(e.currentTarget);
    }

    listenScroll = (e) => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: Math.max(Math.floor(e.target.scrollTop), 0),
        })
    }

    getBackgroundColors = (value) => {
        return [gradient('#871ac7', '#17cde6', 1639)[value], gradient('#e73c7e', '#17e655', 1639)[value]]
        // return  value < 1000 ? ['#871ac7', '#e73c7e'] : ['#17cde6', '#17e655']
    }

    render() {
        const { page, showMenu, anchorEl, theposition } = this.state;

        const [color1, color2] = this.getBackgroundColors(theposition)

        return(
            <Flex
                width="100%"
                height={`100vh`}
                overflow='hidden'
                // background={`linear-gradient(-45deg, ${color1}, ${color2}, ${color1}, ${color2})`}
                // animation='gradient 10s ease infinite'
                backgroundColor='#0C0C0C'
                backgroundSize='400% 400%'
            >
                <Flex flexDirection='column' height='100%' justifyContent='center' padding='20px' color='white'>
                    <Link 
                        target='_blank'
                        href={LinkedInLink}
                        margin='8px 0'
                        width='40px'
                        height='40px'
                        cursor='pointer'
                        boxShadow='none !important'
                        borderRadius='50%'
                        _hover={{bgColor: 'rgba(255,255,255,25%)', border: '1px solid white'}}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <LinkedInIcon />
                    </Link>
                    <Link 
                        target='_blank'
                        href={githubLink}
                        margin='8px 0'
                        width='40px'
                        height='40px'
                        cursor='pointer'
                        boxShadow='none !important'
                        borderRadius='50%'
                        _hover={{bgColor: 'rgba(255,255,255,25%)', border: '1px solid white'}}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <GitHubIcon />
                    </Link>
                    <Link 
                        target='_blank'
                        href={instagramLink} 
                        margin='8px 0'
                        width='40px'
                        height='40px'
                        cursor='pointer'
                        boxShadow='none !important'
                        borderRadius='50%'
                        _hover={{bgColor: 'rgba(255,255,255,25%)', border: '1px solid white'}}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <InstagramIcon />
                    </Link>
                    <Link 
                        target='_blank'
                        href={spotifyLink}
                        margin='8px 0'
                        width='40px'
                        height='40px'
                        cursor='pointer'
                        boxShadow='none !important'
                        borderRadius='50%'
                        _hover={{bgColor: 'rgba(255,255,255,25%)', border: '1px solid white'}}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <SpotifyIcon sx={{ backgroundColor: 'rgba(0,0,0,0)' }} />
                    </Link>
                </Flex>
                <Flex height='100%' flex={1} color='white' justifyContent='center' alignItems='center' flexDirection='column' margin='0 20px' minWidth='280px'>
                    <Box maxWidth='60%'>
                        <Text fontSize='24px' fontFamily='Roboto'>Jamison Kimminau</Text>
                        <Text fontSize='24px'  color='grey' fontFamily='Roboto'>Fullstack Developer</Text>
                        <Text margin='60px 0' fontFamily='Lato' fontSize='18px'>Welcome to Hysteria House. This is my playground for various projects and interests. Enjoy your stay.</Text>
                        <Text margin='60px 0' fontFamily='Lato' fontSize='18px'>Inspired by a unique background of modern art and open-source education, I strive to create software that feels organic.</Text>
                        <Flex>
                            <Button minWidth='86px' backgroundColor='white' color='black' borderRadius='0' border='1px solid white' _hover={{ boxShadow: `0 0 20px purple`, color: 'white', backgroundColor: 'rgba(0,0,0,0)'}}>Explore</Button>
                            <Button minWidth='116px' backgroundColor='white' color='black' borderRadius='0' border='1px solid white' margin='0 20px' as={RouterLink} to='/contact' _hover={{ boxShadow: `0 0 20px purple`, color: 'white', backgroundColor: 'rgba(0,0,0,0)'}}>Contact Me</Button>
                        </Flex>
                    </Box>
                </Flex>
                <Flex height='100%' alignSelf='center' flex='0 0 auto'>
                    <Image boxShadow=' inset 200px 0 100px (-10px)' src="calus.jpeg" maxHeight='100%' />
                </Flex>
                
                {/* <Flex
                        bg="gray.900"
                        alignItems="center"
                        color='whiteAlpha.900'
                        fontSize='0.8f'
                        letterSpacing='1em'
                        padding="0 20px"
                        borderBottomRadius='0.5em'
                        w="100vw"
                        minH='40px'
                        zIndex='1'
                        position='absolute'
                    >
                    <Text userSelect="none">hysteria_house</Text>
                </Flex>
                <div onScroll={this.listenScroll} style={{
                    height: 'auto',
                    overflowY: 'scroll',
                    padding: '80px 0',
                }}>
                    
                    <Flex 
                        className='background'
                        flex={1} 
                        align="center" 
                        justify="center" 
                        height='auto'
                    >
                        <Box 
                            w="80%"
                            // maxW="600px"
                            h='auto'
                            margin='20px 0'
                            borderWidth="1px"
                            borderRadius="15px"
                            backgroundColor="gray.900"
                            boxShadow={`inset 0 0 10px ${color1}`}
                            overflow='hidden'
                        >
                            <Flex align="center" direction="column"  h='100%'>
                                <HomePage nextPage={this.nextPage}/>
                            </Flex>
                        </Box>
                        <Box 
                            w="80%"
                            // maxW="600px"
                            h='auto'
                            margin='20px 0'
                            borderWidth="1px"
                            borderRadius="15px"
                            backgroundColor="gray.900"
                            boxShadow={`inset 0 0 10px ${color2}`}
                            overflow='hidden'
                        >
                            <Flex align="center" direction="column"  h='100%'>
                                <ProfessionalExperience nextPage={this.nextPage}/>
                            </Flex>
                        </Box>
                        <Box 
                            w="80%"
                            // maxW="600px"
                            h='auto'
                            margin='20px 0'
                            borderWidth="1px"
                            borderRadius="15px"
                            backgroundColor="gray.900"
                            boxShadow={`inset 0 0 10px ${color2}`}
                            overflow='hidden'
                        >
                            <Flex align="center" direction="column"  h='100%'>
                                <Education nextPage={this.nextPage}/>
                            </Flex>
                        </Box>
                        <Box 
                            w="80%"
                            // maxW="600px"
                            h='auto'
                            margin='20px 0'
                            borderWidth="1px"
                            borderRadius="15px"
                            backgroundColor="gray.900"
                            boxShadow={`inset 0 0 10px ${color2}`}
                            overflow='hidden'
                        >
                            <Flex align="center" direction="column"  h='100%'>
                                <Portfolio nextPage={this.nextPage}/>
                            </Flex>
                        </Box>
                    </Flex>
                </div> */}
            </Flex>
        );
    }

    getPageContent = () => {
        const { page } = this.state;

        switch(page) {
            case 3:
                return <Portfolio nextPage={this.nextPage}/>
            case 2:
                return <Education nextPage={this.nextPage}/>
            case 1:
                return <ProfessionalExperience nextPage={this.nextPage}/>
            case 0:
            default:
                return <HomePage nextPage={this.nextPage}/>
        }
    }

    nextPage = () => {
        const { page } = this.state;
        this.setState({page: page > 2 ? 0 : page + 1})
    }

    prevPage = () => {
        const { page } = this.state;
        this.setState({page: page === 0 ? 1 : page - 1})
    }
}

export default Home;
