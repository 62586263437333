import React, { useState, useCallback, useEffect } from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button, Link, position, Spinner } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import SpotifyWebApi from "spotify-web-api-node"
import useAuth from '../../utils'
import { debounce } from "lodash"
import { getLyrics, getSong } from 'genius-lyrics-api';
import NowPlaying from './NowPlaying';

const SidebarPage = {
  Lyrics: 'Lyrics',
  Stats: 'Stats',
  Album: 'Album',
  Media: 'Media',
  Shop: 'Shop',
  Profile: 'Profile',
}

function Sidebar({ lyrics, isLoadingLyrics }) {
  const [currentPage, setCurrentPage] = useState(SidebarPage.Lyrics)

  return (
    <AnimatePresence>
      {lyrics  && <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
        <Box position='absolute' right='0' h='100%' border='1px solid #4d4d4d' w='20%'  textAlign='center' zIndex='2'>
          <Flex flexDirection='column' position='absolute' left='-70px'  marginRight='20px' zIndex='2'>
            <Flex backgroundColor='#c1c1c1' alignItems='center' fontSize='20px' justifyContent='center' cursor='pointer' margin='10px 0' border='1px solid #4d4d4d' w='50px' h='50px'>
              L
            </Flex>
            <Flex backgroundColor='#c1c1c1' alignItems='center' fontSize='20px' justifyContent='center' cursor='pointer' margin='10px 0' border='1px solid #4d4d4d' w='50px' h='50px'>
              S
            </Flex>
            <Flex backgroundColor='#c1c1c1' alignItems='center' fontSize='20px' justifyContent='center' cursor='pointer' margin='10px 0' border='1px solid #4d4d4d' w='50px' h='50px'>
              A
            </Flex>
            <Flex backgroundColor='#c1c1c1' alignItems='center' fontSize='20px' justifyContent='center' cursor='pointer' margin='10px 0' border='1px solid #4d4d4d' w='50px' h='50px'>
              M
            </Flex>
            <Flex backgroundColor='#c1c1c1' alignItems='center' fontSize='20px' justifyContent='center' cursor='pointer' margin='10px 0' border='1px solid #4d4d4d' w='50px' h='50px'>
              P
            </Flex>
          </Flex>
          <Flex justifyContent='center' alignItems='center' flexDirection='column' backgroundColor='#C1C1C1' overflowY='scroll' h='100%'>
            <Box overflow='auto' padding='20px'>
              <AnimatePresence>
                {isLoadingLyrics ? <Spinner/> : <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>{lyrics.split('\n').map(line => <div>{line}</div>)}</motion.div>}
              </AnimatePresence>
            </Box>
          </Flex>
        </Box>
      </motion.div>}
    </AnimatePresence>
  );
}

export default Sidebar;
