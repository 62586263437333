import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './components/Home.js'
import Gallery from './components/Gallery.js'
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import MusicNerd from './components/MusicNerd/MusicNerd.js';
import ContactMe from './components/ContactMe.js';

function App() {
  const [title, setTitle] = useState(undefined)

  const updateTitle =  (e) => {
    setTitle(e.target.value)
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/contact" element={<ContactMe />}/>
        <Route path="/gallery" element={<Gallery />}/>
        <Route path="/musicnerd" element={<MusicNerd/>}/>
      </Routes>
    </Router>
    
  );
}

export default App;
