import React, { Component } from "react";
import * as THREE from "three";
// import { OrbitControls } from "./OrbitControls";

class StarField extends Component {
  unmount = false;

    resize = () => {
      if (this.renderer){
        const canvas = this.renderer.domElement;
        // look up the size the canvas is being displayed
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
      
        // adjust displayBuffer size to match
        if (canvas.width !== width || canvas.height !== height) {
          // you must pass false here or three.js sadly fights the browser
          this.renderer.setSize(width, height, false);
          this.camera.aspect = width / height;
          this.camera.updateProjectionMatrix();
      
          // update any render target sizes here
        }
      }
    }

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor( 0x000000, 0 ); // the default

    window.addEventListener('resize', this.resize, false)

    renderer.setClearColor( 0x8227e3, 1 );

    // const controls = new OrbitControls( camera, renderer.domElement );

    // const canvas = document.querySelector('canvas')
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';

    this.mount.appendChild( renderer.domElement );

    var ringMaterial = new THREE.PointsMaterial({ color: 0x00eeff, size: 0.2  });
    const ringParticleCount = 100;
    const ringGeometry = new THREE.BufferGeometry();
    this.ringPositions = new Float32Array(ringParticleCount * ringParticleCount * 3);
    for (let i = 0; i < ringParticleCount; i++) {
        for (let j = 0; j < ringParticleCount; j++) {
            this.ringPositions[((i * ringParticleCount) + j) * 3] = (Math.random() * 40) - 20//i - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+1] = (Math.random() * 40) - 20//j - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+2] = (Math.random() * 40) - 20//0
        }
    //   this.ringArray.push({ position: vector, number: i })
    }
    ringGeometry.setAttribute('position', new THREE.BufferAttribute(this.ringPositions, 3));
    const  ringMesh = new THREE.Points(ringGeometry, ringMaterial)
    ringMesh.castShadow = true;
    ringMesh.rotateX(Math.PI / 2)
    scene.add(ringMesh)

    // camera.position.z = 25;
    camera.position.y = 3;
    // camera.rotateX(-Math.PI / 24)
    camera.rotateZ(-Math.PI)
    // create a point light
    const pointLight =
    new THREE.PointLight(0xFFFFFF);

    // set its position
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 130;

    // add to the scene
    scene.add(pointLight);

    var animate = function () {
    if (unmount) {
      return;
    }

    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );
    
      // cubes.forEach(cube => {
      //   cube.rotation.x += 0.01;
      //   cube.rotation.y += 0.01;
      //   cube.position.x += (Math.floor(Math.random() * 2) - 0.5) / 20;
      //   cube.position.y += (Math.floor(Math.random() * 2) - 0.5) / 20;
      //   cube.position.z += (Math.floor(Math.random() * 2) - 0.5) / 20;
      // })

      ringMesh.rotateX(0.001)

      renderer.render( scene, camera );
    };

    animate(this.unmount);
  }

  render() {
    return (
      <div ref={ref => (this.mount = ref)} />
    )
  }
}

export default StarField;