import React, { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import { Box, Link, Image, Flex, Badge, Text, forwardRef, Heading, Button, Input, Textarea } from "@chakra-ui/react";
import HomeIcon from '@mui/icons-material/Home';
import { Link as RouterLink } from 'react-router-dom'

const ContactMe = (props) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleInputChange = (event) => {
        const target = event.target;

        switch (target.name) {
            case 'name':
                setName(target.value)
                return
            case 'email':
                setEmail(target.value)
                return
            case 'message':
                setMessage(target.value)
                return
            default:
                return;
        }
    }

    const sendEmail = () => {
        const templateParams = {
            fromName: name,
            message,
            email,
        }

        if ([name, email, message].find(item => item === '') === undefined){
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_PUB_KEY)
        }
    }

    return <Flex
        width="100%"
        minHeight='100vh'
        backgroundColor='#0C0C0C'
        backgroundSize='400% 400%'
        flexDirection='column'
        color='white'
        justifyContent='center'
        alignItems='center'
    >
        <Box position='absolute' background={`linear-gradient(-45deg, #37EBA9, #5B37EB, #37EBA9, #5B37EB)`} filter={`blur(calc(500px / 5))`} zIndex={1} height='250px' width='250px'/>
        <Link as={RouterLink} to='/' left='20px' top='20px' cursor='pointer' position='absolute' boxShadow='none !important'>
            <HomeIcon />
        </Link>
        <Box fontSize='1.8em' whiteSpace='nowrap' margin='40px'>
            <Box>Contact me for opportunities, advice, or feedback.</Box>
            <Box>Thank you :)</Box>
        </Box>
        <Flex minWidth='400px' flexDir='column' margin='20px' zIndex='2' onChange={handleInputChange}>
            Full name
            <Input name='name' backgroundColor='#0C0C0C'/>
        </Flex>
        <Flex minWidth='400px' flexDir='column' margin='20px' zIndex='2' onChange={handleInputChange}>
            E-mail
            <Input name='email' backgroundColor='#0C0C0C'></Input>
        </Flex>
        <Flex minWidth='400px'  flexDir='column' margin='20px' zIndex='2' onChange={handleInputChange}>
            Message
            <Textarea textOverflow='' name='message' alignItems='start' backgroundColor='#0C0C0C' height='200px' />
        </Flex>
        <Box minWidth='400px'>
            <Button backgroundColor='#0C0C0C' border='1px solid white' onClick={sendEmail}>Submit</Button>
        </Box>
    </Flex>
}

export default ContactMe;